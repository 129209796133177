<template>
  <div
      class="d-flex"
      :class="$store.getters['layout/getInnerWidth']>=991 ? 'justify-content-center' : 'justify-content-left'"
  >
    <div :class="'badge-subscription'">
      <div class="badge-text-m">
        {{ subscription }}
      </div>
    </div>
  </div>
</template>

<script>
  export default  {
    props: {
      subscription: {
        type: String,
        default: this
      }
    }
  }
</script>